import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import * as JsSearch from 'js-search';
import { startCase } from 'lodash';
import Post from '../components/Post';
import PostEvent from '../components/PostEvent';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import { mixedListOfArticlesAndEvents } from '../utils/common';

const dataToSearch = new JsSearch.Search(`id`);
dataToSearch.addIndex(`beitragsname`);
dataToSearch.addIndex(`hashtag`);
dataToSearch.addIndex(`hashtagStartCase`);
dataToSearch.addIndex(`postText`);
dataToSearch.addIndex(`contentModulesSearch`);
dataToSearch.addIndex(`bvogContentModulesSearch`);
dataToSearch.addIndex(`themenschwerpunktSearch`);
dataToSearch.addIndex(`oesterreichbilanz`);
dataToSearch.addIndex(`arbeitsweise`);

class Search extends Component {
  state = {
    search: [],
    searchQuery: ``,
    searchResults: []
  };

  componentDidMount() {
    const getPostsForSearch = this.props.data.allDatoCmsBeitragBvogOfakt.edges.map(({ node }) => ({
      page: 'beitraege',
      id: node.id,
      hashtag: node.hashtag,
      hashtagStartCase: startCase(node.hashtag),
      beitragsname: node.beitragsname,
      link: node.link,
      bvogFrontText: node.bvogFrontText,
      bvogFrontTitel: node.bvogFrontTitel,
      bvogMainPicture: node.bvogMainPicture,
      postText: node.bvogFrontText,
      themenschwerpunkt: node.themenschwerpunkt,
      themenschwerpunktSearch: node.themenschwerpunkt.map(({ link }) => link),
      oesterreichbilanz: node.oesterreichbilanz.map(({ link }) => link),
      arbeitsweise: node.arbeitsweise.map(({ link }) => link),
      bvogContentModules: node.bvogContentModules,
      bvogVeranstaltungen: node.bvogVeranstaltungen,
      bvogContentModulesSearch: node.bvogContentModules.map(content => {
        switch (content.__typename) {
          case 'DatoCmsBvogModText':
            return content.bvogModTextText;

          case 'DatoCmsBvogModVideo':
            return 'video';

          case 'DatoCmsBvogModPodcast':
            return 'audio podcast';

          default:
            return null;
        }
      }),
      apiKey: node.model.apiKey,
      createdAt: node.meta.createdAt
    }));
    const getEventsForSearch = this.props.data.allDatoCmsVeranstaltungen.edges.map(({ node }) => ({
      page: 'veranstaltungen',
      id: node.id,
      hashtag: node.hashtag,
      hashtagStartCase: startCase(node.hashtag),
      beitragsname: node.beitragsname,
      link: node.link,
      bvogMainPicture: node.image,
      postText: node.frontText,
      themenschwerpunkt: node.themenschwerpunkt,
      themenschwerpunktSearch: node.themenschwerpunkt.map(({ link }) => link),
      oesterreichbilanz: node.oesterreichbilanz.map(({ link }) => link),
      arbeitsweise: node.arbeitsweise.map(({ link }) => link),
      contentModules: node.contentModules,
      contentModulesSearch: node.contentModules.map(content => {
        switch (content.__typename) {
          case 'DatoCmsModText':
            return content.modTextText;

          case 'DatoCmsModVideo':
            return 'video';

          case 'DatoCmsModPodcast':
            return 'audio podcast';

          default:
            return null;
        }
      }),
      apiKey: node.model.apiKey,
      beginn: node.beginn,
      ort: node.ort,
      frontText: node.frontText,
      image: node.image
    }));

    dataToSearch.addDocuments([...getPostsForSearch, ...getEventsForSearch]);

    const getQueryFromHeader =
      (this.props.location.state && this.props.location.state.searchQuery) || ``;

    if (getQueryFromHeader) {
      this.setState({ searchQuery: getQueryFromHeader }, () => this.searchData());
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.location.state || this.props.location.state) &&
      (prevProps.location.state && prevProps.location.state.searchQuery) !==
        (this.props.location.state && this.props.location.state.searchQuery)
    ) {
      const getQueryFromHeader =
        (this.props.location.state && this.props.location.state.searchQuery) || ``;

      this.setState({ searchQuery: getQueryFromHeader }, () => this.searchData());
    }
  }

  searchData = () => {
    const { searchQuery } = this.state;
    const queryResult = dataToSearch.search(searchQuery);

    const articles = queryResult.filter(post => post.apiKey === 'beitrag_bvog_ofakt');
    const events = queryResult.filter(post => post.apiKey === 'veranstaltungen');

    const sortedArticles = articles.sort((a, b) =>
      a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
    );
    const sortedEvents = events.sort((a, b) =>
      a.beginn < b.beginn ? 1 : b.beginn < a.beginn ? -1 : 0
    );

    this.setState({ searchResults: mixedListOfArticlesAndEvents(sortedArticles, sortedEvents) });
  };

  render() {
    const { searchResults } = this.state;
    const { data } = this.props;

    return (
      <Layout
        title={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.title}
        description={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.description}
      >
        <div className="common-page article-page">
          <Subheader title="SEARCH" />

          <div className="container">
            <div className="er-template">
              <div className="article-feed">
                {searchResults.length > 0 ? (
                  searchResults.map(node =>
                    node.apiKey === 'veranstaltungen' ? (
                      <PostEvent key={node.id} node={node} isSearchPage />
                    ) : (
                      <Post key={node.id} node={node} page="Search" searchPage={node.page} />
                    )
                  )
                ) : (
                  <p className="search__text">Nichts gefunden</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const searchQuery = props => (
  <StaticQuery
    query={graphql`
      query GetAllPostForSearch {
        allDatoCmsBeitragBvogOfakt(
          sort: { fields: [meta___createdAt], order: DESC }
          filter: { bvog: { eq: true } }
        ) {
          edges {
            node {
              ...DatoBeitragFields
              meta {
                createdAt
              }
              model {
                apiKey
              }
            }
          }
        }

        allDatoCmsVeranstaltungen(sort: { fields: [beginn], order: DESC }) {
          edges {
            node {
              ...DatoVeranstaltungenFields
              meta {
                createdAt
              }
              model {
                apiKey
              }
            }
          }
        }

        datoCmsFixtext(name: { eq: "Search" }) {
          fixtext
          introtext
          seo {
            title
            description
          }
        }
      }
    `}
    render={data => <Search data={data} {...props} />}
  />
);

export default searchQuery;
